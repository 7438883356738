import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {Feed} from "./feed";

@Injectable({
  providedIn: "root",
})
export class FeedService {
  private baseUrl = environment.api + "/feed";
  private cache: Feed[];

  constructor(private httpClient: HttpClient) {}

  list(): Observable<Feed[]> {
    if (this.cache) {
      return of(this.cache);
    }
    return this.httpClient.get<Feed[]>(environment.api + "/feeds").pipe(
      map((feeds) => {
        return feeds.map((feed) => {
          return this.mapFeed(feed);
        });
      }),
      tap((feeds: Feed[]) => {
        this.cache = feeds;
      }),
    );
  }

  delete(feedId: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + "/" + feedId);
  }

  updateTitle(feedId: string, title: string): Observable<any> {
    return this.httpClient.put(this.baseUrl + "/" + feedId + "/updateTitle?newName=" + title, {});
  }

  create(name: string, position: number): Observable<Feed> {
    return this.httpClient.post<Feed>(this.baseUrl, {name, position}).pipe(map((feed) => this.mapFeed(feed)));
  }

  private mapFeed(feed): Feed {
    return new Feed(feed.Name, feed.Position, feed.Id);
  }

  clearCache() {
    this.cache = null;
  }
}
