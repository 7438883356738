import {Stream} from "@stream/stream.interface";
import {Subject} from "rxjs";

export class Feed {
  addStream$: Subject<Stream> = new Subject();

  constructor(
    public Name: string,
    public Position: number,
    public Id?: string,
  ) {}
}
